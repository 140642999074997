import './MoreOptions.scss'

import { Checkbox, FormControlLabel, Dialog, DialogContent, DialogActions, Button } from '@mui/material'

export function MoreOptions({ filters, onChange, onClose }) {
  return (
    <Dialog open onClose={onClose}>
      <div className="MoreOptions">
        <DialogContent sx={{ color: '#555' }}>
          <div style={{ fontWeight: 600, fontSize: 18 }}>Refine search results by:</div>
          <div style={{ marginTop: 20 }}>Data Collection Frequency:</div>
          <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.freqW || false}
                  onChange={e => onChange('freqW', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Weekly"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.freqM || false}
                  onChange={e => onChange('freqM', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Monthly"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.freqY || false}
                  onChange={e => onChange('freqY', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Annually"
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.age || false}
                  onChange={e => onChange('age', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Available by age"
            />
          </div>
          <div style={{ marginTop: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.gender || false}
                  onChange={e => onChange('gender', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Available by gender"
            />
          </div>
          <div style={{ marginTop: 5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.race || false}
                  onChange={e => onChange('race', e.target.checked)}
                  color="secondary"
                  size="small"
                />
              }
              label="Available by race"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Done</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
