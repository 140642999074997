import './CategoryPage.scss'

import { useEffect, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import * as api from '../api'
import { LOADING } from '../constants'
import { Error404 } from './errors'
import { ContainerSpinner } from './spinners'
import { TitleBar } from './TitleBar'
import { SubcategoryCards } from './SubcategoryPage'

export function CategoryPage() {
  const match = useRouteMatch()
  return (
    <div className="CategoryPage">
      <Route path={`${match.path}/:id`}>
        <CategoryDetail />
      </Route>
    </div>
  )
}

export function CategoryDetail() {
  const [category, setCategory] = useState(LOADING)
  const match = useRouteMatch()
  const id = match.params.id
  useEffect(() => {
    api.categories
      .get(id)
      .then(category => setCategory(category))
      .catch(err => {
        console.error(err)
        setCategory(null)
      })
  }, [id])

  if (category === LOADING) return <ContainerSpinner />
  if (!category) return <Error404 />

  return (
    <div className="CategoryDetail container pb100">
      <TitleBar>Category: {category.name}</TitleBar>
      <div className="subcategories">
        {category.subcategories.map((s, i) => (
          <Subcategory key={i} subcategory={s} />
        ))}
      </div>
      <div className="fade-bar" />
    </div>
  )
}

export function CategoryListItem({ data }) {
  return (
    <div className="CategoryListItem">
      <div className="header">{data.name}</div>
    </div>
  )
}

export function Subcategory({ subcategory }) {
  if (!(subcategory.reports.length && subcategory.orgs.length)) {
    return null
  }

  return (
    <div className="Subcategory" id={subcategory.name.replace(/\W/g, '_')}>
      <div className="header">{subcategory.name}</div>
      <SubcategoryCards subcategory={subcategory} />
    </div>
  )
}
