import './HomePage.scss'

import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Autocomplete, Button, ButtonGroup, Chip, TextField } from '@mui/material'
import { Search as SearchIcon, ArrowCircleDown as ArrowLeftIcon } from '@mui/icons-material'
import TuneIcon from '@mui/icons-material/Tune'
import _ from 'lodash'
import * as api from '../api'
import { FILTER_TYPES_OPTIONS, MORE_OPTIONS_KEYS, SearchBoxLabel, SearchType } from '../constants'
import { Filters } from './Filters'
import { Spinner } from './spinners'
import { SearchResults } from './SearchResults'
import { Bubble } from './Bubble'
import { Menu } from './Menu'
import { MoreOptions } from './MoreOptions'

let prevState = null
let scrollTop = 0

class HomePage extends Component {
  pageName = 'HomePage'

  state = prevState || {
    searchText: '',
    searchText2: '',
    searchType: SearchType.INDICATOR,
    filters: {},
    searchResults: null,
    filterOptions: {},
    suggestions: [],
    loadingSuggestions: false,
    showMoreOptions: false
  }

  componentDidMount() {
    document.documentElement.scrollTop = scrollTop
    if (!prevState) {
      this.init()
      this.fetchFilters()
      this.maybeSearch()
    }
  }

  componentWillUnmount() {
    prevState = this.state
    scrollTop = document.documentElement.scrollTop
  }

  init = () => {
    const params = new URLSearchParams(window.location.search)
    let nextState = { filters: {} }
    for (const [k, v] of params) {
      if (k === 'q') {
        nextState.searchText = v
      } else if (k === 'type') {
        nextState.searchType = v
      } else if (MORE_OPTIONS_KEYS.includes(k)) {
        nextState.filters[k] = v === 'true'
      } else {
        nextState.filters[k] = v.split(',').map(Number)
      }
    }

    this.setState(nextState)
  }

  maybeSearch = prevLocation => {
    const { location } = this.props
    if (location.search && (!prevLocation || location.search !== prevLocation.search)) {
      this.search(new URLSearchParams(location.search))
    }
  }

  fetchFilters = async () => {
    const filterOptions = await api.filters.list()
    this.setState({ filterOptions })
  }

  fetchSuggestions = async (searchText: String) => {
    const { searchType } = this.state
    const params = new URLSearchParams()
    params.set('q', searchText)
    params.set('type', searchType)
    params.set('suggestions', 'true')
    try {
      this.setState({ suggestions: [], loadingSuggestions: true })
      const suggestions = await api.search(params)
      this.setState({ suggestions, loadingSuggestions: false })
    } catch {
      this.setState({ loadingSuggestions: false })
    }
  }

  fetchSuggestionsDebounced = _.debounce(this.fetchSuggestions, 500)

  onSubmit = (e: Event) => {
    e?.preventDefault()
    document.documentElement.scrollTop = 0
    const { history } = this.props
    const { searchText, searchType, filters } = this.state
    const params = new URLSearchParams()
    params.set('q', searchText)
    params.set('type', searchType)
    for (const [k, v] of Object.entries(filters)) {
      if (!MORE_OPTIONS_KEYS.includes(k) || searchType === SearchType.INDICATOR) {
        params.set(k, v)
      }
    }
    history.push({ pathname: '/', search: params.toString() })
    this.search(params)
  }

  search = async (params: URLSearchParams) => {
    this.setState({ loading: true }, async () => {
      try {
        const searchResults = await api.search(params)
        this.setState({ loading: false, searchResults })
      } catch (err) {
        console.error(err)
        this.setState({ loading: false })
      }
    })
  }

  onFilterChange = (prop, value) => {
    const { history } = this.props
    if (prop === null) {
      history.push({ pathname: '/', search: '' })
      this.setState({ filters: {}, searchText: '', searchResults: null })
    } else if (typeof prop === 'object' && value === undefined) {
      const filters = prop
      this.setState({ filters })
    } else {
      let filters = { ...this.state.filters }
      filters[prop] = value
      this.setState({ filters })
    }
  }

  toggleMoreOptionsDialog = () => {
    this.setState({ showMoreOptions: !this.state.showMoreOptions })
  }

  onMoreOptionsChange = (prop, value) => {
    this.onFilterChange(prop, value)
  }

  onResetClick = () => {
    this.onFilterChange(null)
  }

  getNextFilter = () => {
    const { filters } = this.state
    let type = FILTER_TYPES_OPTIONS[0][0]
    for (const [opt] of FILTER_TYPES_OPTIONS) {
      if (!filters.find(f => f.type === opt)) {
        type = opt
        break
      }
    }
    return { type, value: '' }
  }

  getDescription = () => {
    const descriptions = {
      [SearchType.INDICATOR]: 'Quantitative metrics',
      [SearchType.REPORT]: 'Applications and translation of data along a theme or issue',
      [SearchType.ORG]: 'Publishers and funders of evidence and indicator sources',
      [SearchType.ALL]: 'Indicators, evidence and organizations by category/subcategory'
    }

    return descriptions[this.state.searchType]
  }

  renderMoreOptionsSummary = () => {
    const { filters, searchType } = this.state
    if (searchType !== SearchType.INDICATOR) {
      return null
    }

    const { freqY, freqW, freqM, age, gender, race } = filters
    let ui = []

    if (freqY || freqM || freqW) {
      // prettier-ignore
      ui.push(<Chip key="freq" size="small" label="Frequency" variant="outlined" onDelete={() => { 
        this.setState({ filters: { ...filters, freqY: undefined, freqM: undefined, freqW: undefined } }) 
        this.onSubmit()
      }} />)
    }

    if (age) {
      // prettier-ignore
      ui.push(<Chip key="age" size="small" label="Age" variant="outlined" onDelete={() => { 
        this.setState({ filters: { ...filters, age: undefined } }) 
        this.onSubmit()
      }} />)
    }

    if (gender) {
      // prettier-ignore
      ui.push(<Chip key="gender" size="small" label="Gender" variant="outlined" onDelete={() => { 
        this.setState({ filters: { ...filters, gender: undefined } }) 
        this.onSubmit()
      }} />)
    }

    if (race) {
      // prettier-ignore
      ui.push(<Chip key="race" size="small" label="Race" variant="outlined" onDelete={() => { 
        this.setState({ filters: { ...filters, race: undefined } }) 
        this.onSubmit()
      }} />)
    }

    return <div className="more-options-summary">{ui}</div>
  }

  render() {
    const {
      searchText,
      searchText2,
      searchType,
      searchResults,
      filters,
      filterOptions,
      suggestions,
      loading,
      loadingSuggestions,
      showMoreOptions
    } = this.state

    return (
      <div className="HomePage">
        <div className={`searchbox searchType-${searchType}`}>
          <div className="header">
            <ButtonGroup color="secondary">
              <Button
                variant={searchType === SearchType.INDICATOR ? 'contained' : 'outlined'}
                onClick={() =>
                  this.setState({
                    searchType: SearchType.INDICATOR,
                    searchResults: null,
                    filters: { ...filters, subcategories: [], funder_ids: [], evaluator_ids: [], collector_ids: [] }
                  })
                }
              >
                Indicators
              </Button>
              <Button
                variant={searchType === SearchType.REPORT ? 'contained' : 'outlined'}
                onClick={() =>
                  this.setState({
                    searchType: SearchType.REPORT,
                    searchResults: null,
                    filters: { ...filters, subcategories: [], funder_ids: [], evaluator_ids: [], collector_ids: [] }
                  })
                }
              >
                Evidence
              </Button>
              <Button
                variant={searchType === SearchType.ORG ? 'contained' : 'outlined'}
                onClick={() =>
                  this.setState({
                    searchType: SearchType.ORG,
                    searchResults: null,
                    filters: { ...filters, subcategories: [], funder_ids: [], evaluator_ids: [], collector_ids: [] }
                  })
                }
              >
                Organizations
              </Button>
              <Button
                variant={searchType === SearchType.ALL ? 'contained' : 'outlined'}
                onClick={() =>
                  this.setState({
                    searchType: SearchType.ALL,
                    searchResults: null,
                    filters: { ...filters, subcategories: [], funder_ids: [], evaluator_ids: [], collector_ids: [] }
                  })
                }
              >
                All
              </Button>
            </ButtonGroup>
          </div>
          <Bubble>{this.getDescription()}</Bubble>
          <form onSubmit={this.onSubmit}>
            <div className="keyword-box">
              {searchType === SearchType.ALL ? null : (
                <Autocomplete
                  id="suggestions"
                  freeSolo
                  includeInputInList
                  options={suggestions.map(option => option.name)}
                  loading={loadingSuggestions}
                  noOptionsText="No suggestions."
                  filterOptions={x => x}
                  value={searchText}
                  onChange={(e, value) => {
                    if (value == null) {
                      this.setState({ searchText: '' })
                    } else {
                      this.setState({ searchText: value }, () => this.onSubmit())
                    }
                  }}
                  inputValue={searchText2}
                  onInputChange={(e, value) => {
                    this.setState({ searchText2: value })
                    this.fetchSuggestionsDebounced(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      type="text"
                      label={SearchBoxLabel[searchType]}
                      color="secondary"
                      variant="outlined"
                      size="small"
                      autoCapitalize="off"
                      fullWidth
                    />
                  )}
                />
              )}
            </div>
            <Filters searchType={searchType} values={filters} options={filterOptions} onChange={this.onFilterChange} />

            {searchType === SearchType.INDICATOR ? (
              <div className="more-options">
                <Button
                  size="small"
                  variant="text"
                  color="secondary"
                  startIcon={<TuneIcon />}
                  onClick={this.toggleMoreOptionsDialog}
                >
                  More Options
                </Button>
                {this.renderMoreOptionsSummary()}
              </div>
            ) : null}

            <div className="footer">
              <Button size="small" variant="outlined" color="primary" onClick={this.onResetClick}>
                RESET
              </Button>
              <Button type="submit" variant="contained" color="primary" startIcon={<SearchIcon />} fullWidth>
                Search
              </Button>
            </div>
          </form>
        </div>
        {loading ? (
          <div className="SearchResults" style={{ paddingTop: 30 }}>
            <Spinner />
          </div>
        ) : searchResults === null ? (
          <BlankPageMsg />
        ) : searchResults.length === 0 ? (
          <NoResults />
        ) : (
          <SearchResults searchType={searchType} searchResults={searchResults} />
        )}
        <Menu />

        {showMoreOptions ? (
          <MoreOptions filters={filters} onChange={this.onMoreOptionsChange} onClose={this.toggleMoreOptionsDialog} />
        ) : null}
      </div>
    )
  }
}

HomePage = withRouter(HomePage)
export { HomePage }

export function NoResults() {
  return <div className="NoResults">No results.</div>
}

function BlankPageMsg() {
  return (
    <div className="BlankPageMsg">
      <ArrowLeftIcon />
      Please use the pane to the left to start a search by keyword and/or category
    </div>
  )
}
