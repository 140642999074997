import './IndicatorPage.scss'

import { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import _ from 'lodash'
import { Button, Card, CardContent } from '@mui/material'
import { OpenInNew, Description as DescriptionIcon, Dashboard as DashboardIcon } from '@mui/icons-material'
import * as api from '../api'
import { LOADING } from '../constants'
import { Error404 } from './errors'
import { ContainerSpinner } from './spinners'
import { Fields } from './Fields'
import { TitleBar } from './TitleBar'
import { CategoryIcon } from './CategoryIcon'
import { Cards } from './Cards'

export function IndicatorPage() {
  const match = useRouteMatch()
  return (
    <div className="IndicatorPage">
      <Route path={`${match.path}/:id`}>
        <IndicatorDetail />
      </Route>
    </div>
  )
}

export function IndicatorDetail() {
  const [indicator, setIndicator] = useState(LOADING)
  const match = useRouteMatch()
  const id = match.params.id
  useEffect(() => {
    api.indicators
      .get(id + '?include=indicator.reports')
      .then(indicator => setIndicator(indicator))
      .catch(err => {
        console.error(err)
        setIndicator(null)
      })
  }, [id])

  if (indicator === LOADING) return <ContainerSpinner />
  if (!indicator) return <Error404 />

  const rlist = indicator.reports.filter(r => r.type === 'Report')
  const dlist = indicator.reports.filter(r => r.type === 'Dashboard')

  // prettier-ignore
  const fields = _.compact([
    ['Source', <><NavLink to={`/orgs/${indicator.collector.id}`}>{indicator.collector.name}</NavLink>{indicator.direct_source_link ? <a className="small" href={indicator.direct_source_link} target="_blank" rel="noreferrer">(Link to source <OpenInNew sx={{ fontSize: 12 }} />)</a> : null}</>],
    indicator.date ? ['Years Available', indicator.date && indicator.date2 && indicator.date !== indicator.date2 ? `${indicator.date} - ${indicator.date2}` : indicator.date] : null,
    indicator.frequency ? ['Data Collection Frequency', indicator.frequency] : null,
    indicator.age ? ['Age Breakdown', indicator.age] : null,
    indicator.gender ? ['Gender Breakdown', indicator.gender] : null,
    indicator.race ? ['Race Breakdown', indicator.race] : null,
    ['Geography', indicator.geography],
    ['Category', <NavLink to={`/categories/${indicator.category.id}`}>{indicator.category.name} <CategoryIcon name={indicator.category.name}/></NavLink>],
    ['Subcategory', <NavLink to={`/subcategories/${indicator.subcategory.id}`}>{indicator.subcategory.name}</NavLink>],
    // rlist.length ? ['Reports Cited in', <div className="links">{rlist.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>] : null,
    // dlist.length ? ['Dashboards Cited in', <div className="links">{dlist.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>] : null,
  ])

  let cards = []
  if (rlist.length) {
    cards.push(
      <Card key="rlist">
        <CardContent>
          <div className="header">
            <DescriptionIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Reports Cited in</div>
          </div>
          <div className="links">
            {rlist.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (dlist.length) {
    cards.push(
      <Card key="dlist">
        <CardContent>
          <div className="header">
            <DashboardIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Dashboards Cited in</div>
          </div>
          <div className="links">
            {dlist.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="IndicatorDetail container pb100">
      <TitleBar>Indicator: {indicator.name}</TitleBar>
      <Fields fields={fields} />
      {indicator.available_in_policy_map ? (
        <div style={{ marginTop: 25 }}>
          <Button
            variant="contained"
            color="primary"
            href="https://hawaii.policymap.com/login?redir=%2Fnewmaps"
            target="_blank"
          >
            Available in PolicyMap
          </Button>
        </div>
      ) : null}
      <Cards>{cards}</Cards>
    </div>
  )
}

export function IndicatorListItem({ data }) {
  return (
    <div className="IndicatorListItem">
      <div className="header">{data.name}</div>
    </div>
  )
}
