export const FILTER_TYPES_OPTIONS = [
  ['categories', 'Category'],
  ['subcategories', 'Subcategory'],
  ['funders', 'Funder'],
  ['collectors', 'Collector'],
  ['evaluators', 'Evaluator']
]

export const LOADING = Symbol('LOADING')

export const SearchType = {
  REPORT: 'REPORT',
  INDICATOR: 'INDICATOR',
  ORG: 'ORG',
  ALL: 'ALL'
}

export const SearchBoxLabel = {
  REPORT: 'Search by keyword',
  INDICATOR: 'Search by keyword',
  ORG: 'Search by keyword',
  ALL: 'Search by keyword'
}

export const MORE_OPTIONS_KEYS = ['freqW', 'freqM', 'freqY', 'age', 'gender', 'race']
